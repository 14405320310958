<template>
  <v-card flat tile color="grey lighten-4">
    <v-card-title>
      <h3 class="checkout-section-title mx-auto mb-2">
        {{ $t("checkout.yourOrder") }}
      </h3>
    </v-card-title>
    <v-card-text class="pa-md-4 pa-0">
      <v-card flat tile>
        <v-card-text class="pa-md-4 pa-0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="header-table">
                  <th class="text-left">{{ $t("cart.product") }}</th>
                  <th class="text-right">{{ $t("cart.subtotal") }}</th>
                </tr>
              </thead>
              <tbody v-if="cartProducts.length && cartProducts[0] !== null">
                <tr
                  v-for="item in cartProducts"
                  :key="item.productId"
                  class="grey--text"
                >
                  <td>{{ item.name }} x {{ item.qty }}</td>
                  <td class="text-right">
                    {{
                      $t("homepage.currency", {
                        price: formatThousands(
                          getPrice(item.amountWithVat, item.qty)
                        ),
                      })
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="header-item-title">
                    {{ $t("cart.subtotal") }}
                  </th>
                  <td class="text-right primary--text">
                    {{
                      $t("homepage.currency", {
                        price: formatThousands(total),
                      })
                    }}
                  </td>
                </tr>
                <tr v-if="user && user.discount">
                  <th class="header-item-title">
                    {{ $t("cart.discount") }}
                  </th>
                  <td class="text-right primary--text">
                    {{
                      $t("homepage.currency", {
                        price: discount,
                      })
                    }}
                  </td>
                </tr>
                <tr v-if="selectedShippingMethod">
                  <th class="header-item-title">
                    {{ $t("checkout.delivery") }}
                  </th>
                  <td class="text-right">
                    {{ selectedShippingMethod.name }}
                  </td>
                </tr>
                <tr v-if="selectedPaymentMethod">
                  <th class="header-item-title">
                    {{ $t("checkout.payment") }}
                  </th>
                  <td class="text-right">
                    {{ selectedPaymentMethod.name }}
                  </td>
                </tr>
                <tr class="order-total">
                  <th>{{ $t("cart.total") }}</th>
                  <td class="text-right">
                    <span v-if="user && discount" class="primary--text">
                      {{
                        $t("homepage.currency", {
                          price: formatThousands(totalDiscount),
                        })
                      }}
                    </span>
                    <span v-else class="primary--text">{{
                      $t("homepage.currency", {
                        price: formatThousands(total),
                      })
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { formatThousands } from "@/Helper";

export default {
  name: "Summary",
  mixins: [formatThousands],
  props: ["form"],
  computed: {
    ...mapGetters([
      "cartProducts",
      "user",
      "shippingMethods",
      "paymentMethods",
    ]),
    total() {
      if (this.cartProducts[0] === null) return null;
      return this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);
    },
    totalDiscount() {
      if (this.cartProducts[0] === null) return null;
      let total = this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);

      if (this.discount) {
        return parseInt(total) - this.discount;
      }

      return total;
    },
    discount() {
      if (this.user && this.user.discount) {
        return Math.round(this.total * this.user.discount);
      }

      return false;
    },
    selectedShippingMethod() {
      return this.shippingMethods.find(
        (p) => p.id === this.form.shippingMethodId
      );
    },
    selectedPaymentMethod() {
      return this.paymentMethods.find(
        (p) => p.id === this.form.paymentMethodId
      );
    },
  },
  methods: {
    getPrice(price, quantity) {
      return quantity * price;
    },
  },
};
</script>

<style lang="scss">
.header-table {
  th {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
  }
}
th.header-item-title {
  font-size: 14px;
}
tr.order-total {
  th {
    font-size: 18px;
    font-weight: 400;
  }
  td {
    font-size: 22px;
    font-weight: 600 !important;
  }
}
</style>
