import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store/index.js'
import router from './router'
import axios from 'axios'
import ValidationRules from './utilities/ValidationRules'
import VueGtm from 'vue-gtm';

Vue.config.productionTip = false;
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM,
  enabled: !!process.env.VUE_APP_GTM, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
});
window.axios = axios;
window.validationRules = ValidationRules;

axios.interceptors.response.use(function (response) {
  // Do something with response data
  if (response.data.errors && response.data.errors.length) {
    return Promise.reject(response.data);
  }
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error.response);
});

import {mapActions} from 'vuex';
import i18n from './i18n'

new Vue({
  vuetify,
  router,
  store,

  methods: {
    ...mapActions([
      'changeCategory'
    ])
  },

  created() {
    // eslint-disable-next-line
    router.afterEach((to, from) => {
      if (to.name === 'Homepage') {
        this.changeCategory(process.env.VUE_APP_MAIN_CATEGORY);
      }
    });
  },

  i18n,
  render: h => h(App)
}).$mount('#app');
