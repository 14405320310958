<template>
    <div class="px-sm-0 px-2">
        <v-btn block :loading="loading" :disabled="loading" color="primary" depressed tile large @click="submit($event)">{{$t('checkout.placeOrder')}}</v-btn>
        <p class="caption mt-2">{{ $t('checkout.privacyPolicyAgreementText') }}<a
                target="_blank"
                :href="$t('footer.privacyPolicyPdf')"
                @click.stop
        >{{ $t('checkout.privacyPolicyAgreementLink') }}</a>.</p>
        <v-checkbox v-model="form.marketingAgreement" :label="$t('checkout.marketingAgreement')" />
    </div>
</template>

<script>
    export default {
        name: 'PlaceOrder',
        props: ['form', 'loading'],
        data () {
            return {
                rules: window.validationRules.getRules()
            }
        },
        methods: {
            submit(event) {
                this.$emit('submit', event);
            }
        }
    }
</script>
