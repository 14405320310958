<template>
  <v-container class="footer-address white--text">
    <v-row>
      <v-col cols="12" sm="5">
        <p class="mb-0">Botanická zahrada Liberec – LK</p>
        <p class="mb-0">příspěvková organizace</p>
        <p class="mb-0">Purkyňova 630/1</p>
        <p class="mb-0">460 01 Liberec</p>
      </v-col>
      <v-col cols="12" sm="7">
        <p class="mb-0">Otevírací hodiny:</p>
        <p class="mb-0">Po – Ne: 8 – 16 hod. během zimního času</p>
        <p class="mb-0">8 – 18 hod. během letního času</p>
        <p class="mb-0">Dne 1. ledna je výjimka: otevřeno 10 – 16 hod.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterAddress",
};
</script>

<style lang="scss">
.footer-address {
  p {
    font-weight: normal;
  }
}
</style>
