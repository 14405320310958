<template>
  <div>
    <category-header :show-categories="false" />    
    <products />
  </div>
</template>

<script>
import CategoryHeader from "../components/Category/CategoryHeader";
import Products from "../components/Category/Products";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CategoryPage",
  metaInfo() {
    return {
      title: this.currentCategory ? this.currentCategory.name : "",
    };
  },
  watch: {
    "$route.params.alias": function () {
      this.changeCategory({ categoryId: null, langChange: false });
    },
    loadingCategories: function (newValue) {
      if (!newValue) {
        if (this.$router.currentRoute.name === "Homepage") {
          this.changeCategory({
            categoryId: process.env.VUE_APP_MAIN_CATEGORY,
            langChange: false,
          });
        } else {
          this.changeCategory({ categoryId: null, langChange: false });
        }
      }
    },
  },
  mounted() {
    if (this.$router.currentRoute.name === "Homepage") {
      this.changeCategory({
        categoryId: process.env.VUE_APP_MAIN_CATEGORY,
        langChange: false,
      });
    } else {
      this.changeCategory({ categoryId: null, langChange: false });
    }
  },
  components: {
    CategoryHeader,
    Products,
  },
  computed: {
    ...mapGetters(["allProducts", "currentCategory", "loadingCategories"]),
  },
  methods: {
    ...mapActions(["changeCategory"]),
  },
};
</script>

