import Vue from "vue"
import Vuex from "vuex"
import * as types from "./mutation-types"
import router from "../router"
import VuexPersistence from "vuex-persist"
import i18n from "../i18n.js"
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({ added: state.added, user: state.user, lang: state.lang })
})

const debug = process.env.NODE_ENV !== "production"

// initial state
const state = {
  checkingCustomer: false,
  loadingCategories: false,
  added: [],
  all: [],
  categories: [],
  currentCategory: {},
  currentProduct: {},
  user: {},
  orderId: null,
  paymentMethods: [],
  paymentMethodsLoaded: false,
  shippingMethods: [],
  shippingMethodsLoaded: false,
  snackbar: {
    active: false,
    text: "",
    type: "",
    button: true
  },
  lang: i18n.locale
}

// getters
const getters = {
  shippingMethodsLoaded: state => state.shippingMethodsLoaded,
  paymentMethodsLoaded: state => state.paymentMethodsLoaded,
  paymentMethods: state => state.paymentMethods,
  shippingMethods: state => state.shippingMethods,
  user: state => state.user,
  orderId: state => state.orderId,
  snackbar: state => state.snackbar,
  checkingCustomer: state => state.checkingCustomer,
  loadingCategories: state => state.loadingCategories,
  currentCategory: state => state.currentCategory,
  currentProduct: state => state.currentProduct,
  allProducts: state => {
    if (state.currentCategory) {
      const category = state.categories.find(
        c => c.alias === state.currentCategory.alias
      )
      if (category) {
        return state.all.filter(item => item.productCategoryId === category.id)
      }
    }
    return state.all
  }, // would need action/mutation if data fetched async
  allCategories: state => state.categories,
  cartProducts: state => {
    return state.added.map(({ productId, qty }) => {
      const product = state.all.find(p => p.id === productId)

      if (!product) return null

      return {
        productId: product.id,
        name: product.name,
        amountWithVat: product.amountWithVat,
        imageUrl: product.imageUrl,
        qty
      }
    })
  }
}

// actions
const actions = {
  handleFormSubmit() {
    return false
  },
  hideSnackbar({ commit }) {
    commit(types.HIDE_SNACKBAR)
  },
  showSuccessSnackbar({ commit }, { message, button }) {
    commit(types.HIDE_SNACKBAR)
    commit(types.SHOW_SNACKBAR, { message, button })
    setTimeout(function() {
      commit(types.HIDE_SNACKBAR)
    }, 4000)
  },
  setOrderId({ commit }, orderId) {
    commit(types.SET_ORDER_ID, { orderId: orderId })
  },
  emptyCart({ commit }) {
    commit(types.EMPTY_CART)
  },
  changeQuantity({ commit }, product) {
    commit(types.CHANGE_QUANTITY, { product: product })
  },
  retrieveShippingMethods({ commit }, lang) {
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
          process.env.VUE_APP_API_CART +
          "/" +
          process.env.VUE_APP_API_ESHOP_ID +
          "/shippingmethod/items?langCode=" +
          lang.toUpperCase()
      )
      .then(response => {
        commit(types.RETRIEVE_SHIPPING_METHODS, { response: response.data })
        commit(types.SHIPPING_METHODS_LOADED)
      })
      .catch(error => {
        console.log(error)
      })
  },
  retrievePaymentMethods({ commit }, lang) {
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
          process.env.VUE_APP_API_CART +
          "/" +
          process.env.VUE_APP_API_ESHOP_ID +
          "/paymentmethod/items?langCode=" +
          lang.toUpperCase()
      )
      .then(response => {
        commit(types.RETRIEVE_PAYMENT_METHODS, { response: response.data })
        commit(types.PAYMENT_METHODS_LOADED)
      })
      .catch(error => {
        console.log(error)
      })
  },
  addToCart({ commit, dispatch }, { product: product, qty: qty }) {
    commit(types.ADD_TO_CART, {
      productId: product.id,
      qty: qty
    })
    if (Vue.gtm.enabled()) {
      Vue.gtm.trackEvent({
        event: "addToCart",
        category: "Ecomerce",
        ecommerce: {
          add: {
            products: [
              {
                id: product.id,
                name: product.name,
                category: product.productCategoryName,
                quantity: qty
              }
            ]
          }
        }
      })
    }
    const message = i18n.t("cart.productAdded", { product: product.name })
    dispatch("showSuccessSnackbar", { message: message, button: true })
  },
  removeFromCart({ commit }, product) {
    commit(types.REMOVE_FROM_CART, {
      productId: product.productId
    })
    if (Vue.gtm.enabled()) {
      Vue.gtm.trackEvent({
        event: "removeFromCart",
        category: "Ecomerce",
        ecommerce: {
          remove: {
            products: [
              {
                id: product.productId,
                name: product.name,
                category: product.productCategoryName,
                quantity: 1
              }
            ]
          }
        }
      })
    }
  },
  retrieveProducts(
    { commit, dispatch, state },
    { response: response, langChange: langChange }
  ) {
    commit(types.RETRIEVE_CATEGORIES, { response: response.data })
    dispatch("changeCategory", { categoryId: null, langChange: langChange })
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
          process.env.VUE_APP_API_PROJECT +
          process.env.VUE_APP_API_PROJECT_KEY +
          "/products?langCode=" +
          state.lang.toUpperCase()
      )
      .then(response => {
        commit(types.RETRIEVE_PRODUCTS, { response: response.data })
        dispatch("changeProduct", { langChange: langChange })
        commit(types.LOADING_CATEGORIES)
      })
      .catch(error => {
        this.onError(error)
      })
  },
  retrieveProductsAndAddToCart({ commit, state }, productId) {
    if (!state.all.length) {
      window.axios
        .get(
          process.env.VUE_APP_ROOT_API +
            process.env.VUE_APP_API_PROJECT +
            process.env.VUE_APP_API_PROJECT_KEY +
            "/products"
        )
        .then(response => {
          commit(types.USER_ADD_TO_CART, {
            response: response.data,
            product: productId
          })
          commit(types.CHECKING_USER)
          router.push("/checkout")
        })
        .catch(error => {
          this.onError(error)
        })
    } else {
      commit(types.USER_ADD_TO_CART, {
        response: state.all,
        product: productId
      })
      commit(types.CHECKING_USER)
      router.push("/checkout")
    }
  },
  setLang({ commit }, lang) {
    commit(types.SET_LANG, { lang })
  },
  retrieveCategories({ dispatch, commit }, { lang, langChange }) {
    commit(types.LOADING_CATEGORIES)
    commit(types.SET_LANG, { lang })
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
          process.env.VUE_APP_API_PROJECT +
          process.env.VUE_APP_API_PROJECT_KEY +
          "/productcategories?langCode=" +
          lang.toUpperCase()
      )
      .then(response => {
        dispatch("retrieveProducts", {
          response: response,
          langChange: langChange
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  changeCategory(
    { commit },
    { categoryId: categoryId, langChange: langChange }
  ) {
    commit(types.CHANGE_CATEGORY, {
      categoryId: categoryId,
      langChange: langChange
    })
  },
  changeProduct({ commit }, { langChange: langChange }) {
    commit(types.CHANGE_PRODUCT, { langChange: langChange })
  },
  checkCustomer(
    { commit, dispatch },
    { customer: customerId, product: productId }
  ) {
    commit(types.CHECKING_USER)
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
          process.env.VUE_APP_API_CART +
          "/" +
          process.env.VUE_APP_API_ESHOP_ID +
          "/customer/" +
          customerId
      )
      .then(response => {
        commit(types.RETRIEVE_USER, { response: response.data })
        if (productId) {
          dispatch("retrieveProductsAndAddToCart", productId)
        } else {
          commit(types.CHECKING_USER)
          router.push("/")
        }
      })
      .catch(() => {
        // eslint-disable-line
        commit(types.CHECKING_USER)
        router.push("/")
      })
  },
  getPaymentFeedback({ commit }, paymentData) {
    commit(types.CHECKING_USER)
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
          "/api/payment/feedback/" +
          process.env.VUE_APP_API_ESHOP_ID +
          paymentData
      )
      .then(response => {
        commit(types.SET_ORDER_ID, { orderId: response.data })
        commit(types.CHECKING_USER)
        router.push("/checkout/success")
      })
      .catch(() => {
        // eslint-disable-line
        commit(types.CHECKING_USER)
        router.push("/")
      })
  },
  checkCustomerCard({ commit }, { coupon: customerCard, email: email }) {
    commit(types.CHECKING_USER)
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
          process.env.VUE_APP_API_CART +
          "/" +
          process.env.VUE_APP_API_ESHOP_ID +
          "/customer?cardNumber=" +
          customerCard +
          "&email=" +
          email
      )
      .then(response => {
        commit(types.RETRIEVE_USER, { response: response.data })
      })
      .catch(() => {
        // eslint-disable-line
        commit(types.CHECKING_USER)
      })
  }
}

// mutations
const mutations = {
  [types.SET_LANG](state, { lang }) {
    state.lang = lang
  },
  [types.CHECKING_USER](state) {
    state.checkingCustomer = !state.checkingCustomer
  },

  [types.USER_ADD_TO_CART](state, { response, product }) {
    let productId = product
    state.all = response
    state.added.push({
      productId,
      qty: 1
    })
  },

  [types.SHOW_SNACKBAR](state, { message, button }) {
    state.snackbar.active = true
    state.snackbar.message = message
    state.snackbar.type = "success"
    state.snackbar.button = button
  },

  [types.EMPTY_CART](state) {
    state.added = []
  },

  [types.HIDE_SNACKBAR](state) {
    state.snackbar.active = false
  },

  [types.SET_ORDER_ID](state, { orderId }) {
    state.orderId = orderId
  },

  [types.ADD_TO_CART](state, { productId, qty }) {
    const record = state.added.find(p => p.productId === productId)

    if (!record) {
      state.added.push({
        productId,
        qty: qty
      })
    } else {
      record.qty++
    }
  },

  [types.REMOVE_FROM_CART](state, { productId }) {
    const record = state.added.find(p => p.productId === productId)

    if (record) {
      state.added = state.added.filter(function(item) {
        return item.productId !== productId
      })
    }
  },

  [types.CHANGE_QUANTITY](state, { product }) {
    const record = state.added.find(p => p.productId === product.productId)

    if (record) {
      record.qty = product.qty
    }
  },

  [types.RETRIEVE_SHIPPING_METHODS](state, { response: methods }) {
    state.shippingMethods = methods
  },

  [types.SHIPPING_METHODS_LOADED](state) {
    state.shippingMethodsLoaded = true
  },

  [types.RETRIEVE_PAYMENT_METHODS](state, { response: methods }) {
    state.paymentMethods = methods
  },

  [types.PAYMENT_METHODS_LOADED](state) {
    state.paymentMethodsLoaded = true
  },

  [types.RETRIEVE_PRODUCTS](state, { response: products }) {
    state.all = products
  },

  [types.RETRIEVE_CATEGORIES](state, { response: categories }) {
    state.categories = categories
  },

  [types.CHANGE_CATEGORY](
    state,
    { categoryId: categoryId, langChange: langChange }
  ) {
    if (langChange && router.currentRoute.name === "Category") {
      let currentCategory = state.categories.find(
        c => c.id === state.currentCategory.id
      )
      state.currentCategory = currentCategory
      router.push("/category/" + currentCategory.alias)
      return
    }
    if (categoryId) {
      state.currentCategory = state.categories.find(c => c.id === categoryId)
      return
    }

    if (router.currentRoute.name === "Homepage") {
      state.currentCategory = state.categories.find(
        c => c.id === process.env.VUE_APP_MAIN_CATEGORY
      )
      return
    }

    let categoryAlias = router.currentRoute.params
    if (typeof categoryAlias.alias !== "undefined") {
      state.currentCategory = state.categories.find(
        c => c.alias === categoryAlias.alias
      )
    }
  },

  [types.CHANGE_PRODUCT](state, { langChange: langChange }) {
    if (langChange && router.currentRoute.name === "Product") {
      let currentProduct = state.all.find(c => c.id === state.currentProduct.id)
      let newAlias = currentProduct.alias

      if (newAlias !== state.currentProduct.alias) {
        router.push("/product/" + currentProduct.alias)
      }
      state.currentProduct = currentProduct
      return
    }
    let productAlias = router.currentRoute.params
    if (typeof productAlias.alias !== "undefined") {
      state.currentProduct = state.all.find(c => c.alias === productAlias.alias)
    }
  },

  [types.LOADING_CATEGORIES](state) {
    state.loadingCategories = !state.loadingCategories
  },

  [types.RETRIEVE_USER](state, { response: user }) {
    state.user = user
  }
}

export default new Vuex.Store({
  state,
  strict: debug,
  getters,
  actions,
  mutations,
  plugins: [vuexLocal.plugin]
})
