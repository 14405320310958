<template>
  <div>
  <v-container fluid class="mb-0">
    <v-row style=" background-color: #ffe1ce;">
      <v-col><h1 class="text-center main-title">{{ title }}</h1></v-col>
    </v-row>
  </v-container>
      
<!--       <div v-if="showCategories">
        <v-btn
          @click="$router.push('/category/' + category.alias)"
          text
          :color="getButtonColor(category.alias)"
          v-for="category in allCategories"
          :key="category.id"
          >{{ category.name }}</v-btn
        >
      </div>
 --> 
 <v-container>
    <div class="text-center main-description" v-if="description" v-html="description"></div>
  </v-container>
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getProductImage } from "@/Helper";

export default {
  name: "CategoryHeader",
  props: ["showCategories"],
  mixins: [getProductImage],
  computed: {
    ...mapGetters(["currentCategory", "allCategories"]),
    title() {
      if (this.currentCategory) {
        return this.currentCategory.name;
      }

      return "Obchod";
    },
    description() {
      if (this.currentCategory) {
        return this.currentCategory.description;
      }

      return null;
    },
    imageUrl() {
      if (this.currentCategory) {
        return this.currentCategory.imageUrl;
      }

      return null;
    },
    activeItem() {
      if (this.currentCategory) {
        return this.currentCategory.alias;
      }

      return null;
    },
  },

  methods: {
    ...mapActions(["changeCategory"]),
    getButtonColor(alias) {
      if (alias === this.activeItem) {
        return "primary";
      }

      return "black";
    },
  },
};
</script>

<style lang="scss">
  .main-title {
    background-color: #ffe1ce;
    font-size: 28pt;
    line-height: 28pt;
    font-weight: 550;
    color:#ff6600;    
  }
  .main-description p {
    margin-bottom: 0px;
    font-weight: bold;
    color: #4a030d;
  }
  .v-btn__content {
    font-weight: bold;
  }    
</style>
