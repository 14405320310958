<template>
  <v-card-text class="black--text">
    <h1 class="mb-3">Więcej informacji o plikach cookie</h1>
    <h3>Co to jest ciasteczko?</h3>
    <p>Jako ciasteczka, ewent. cookie, w HTTP nazywany jest plikiem tekstowym - niewielką ilością danych, które serwery wysyłają do przeglądarki. Zapisuje je na komputerze użytkownika. Za każdym razem, gdy odwiedzasz witrynę, przeglądarka przesyła te dane z powrotem na serwer.</p>
    <p>Pliki cookies są zwykle używane w celu rozróżnienia poszczególnych użytkowników, zazwyczaj przechowują zawartość „koszyka” w sklepie internetowym, preferencje użytkownika i nie tylko. Technicznie jest to seria kodów, które przeglądarka zbiera, a następnie wysyła informacje o Twoim zachowaniu z powrotem do odpowiedniego serwera. Pliki cookie zawierają informacje o tym, jak długo przeglądarka powinna je przechowywać (od jednorazowych do kilku lat).</p>
    <p>Pliki cookie nie są bezpośrednio powiązane z Twoim nazwiskiem ani adresem e-mail, ale często zawierają dane logowania do systemu. Są wspólne dla jednej instalacji przeglądarki. Cookies nie są w żaden sposób zabezpieczone, w tym zebrane dane.</p>
    <h3>Jakich plików cookie używamy?</h3>
    <p>Używamy plików cookie stron trzecich, w szczególności Google Analytics do analizy ruchu. Te pliki cookie są kontrolowane przez strony trzecie i nie mamy dostępu do odczytywania ani zapisywania tych danych. O warunkach korzystania z Google Analytics możesz przeczytać <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">tutaj</a>.</p>
    <h3>Co możesz zrobić?</h3>
    <p>W razie potrzeby możesz odrzucić pliki cookie lub je usunąć. Aby uzyskać instrukcje, jak to zrobić, zobacz sekcję „Pomoc” w przeglądarce internetowej.</p>
  </v-card-text>
</template>

<script>
export default {
  name: "Cookies-pl"
}
</script>