<template>
  <div class="product-detail">
    <v-skeleton-loader
      type="article"
      v-if="loadingCategories || !currentProduct"
    ></v-skeleton-loader>
    <div v-else>
      <h1>{{ currentProduct.name }}</h1>
      <p class="price primary--text font-weight-bold mt-4 mb-6">
        {{
          $t("homepage.currency", {
            price: formatThousands(currentProduct.amountWithVat),
          })
        }}
      </p>
      <p
        v-html="currentProduct.description"
        class="product-description mb-6"
      ></p>
      <p v-if="currentProduct.inStock">{{ $t("product.inStock",{ inStock: formatThousands(currentProduct.inStock)})}}</p>
      <v-layout class="mb-3">
        <vue-numeric-input
          v-model="qty"
          :min="1"
          :max="maxItems"
          align="center"
          size="100px"
          class="ma-0"
        ></vue-numeric-input>
        <v-btn
          class="ml-3"
          color="primary"
          tile
          depressed
          @click="addToCart({ product: currentProduct, qty: qty })"
          >{{ $t("cart.addToCart") }}</v-btn
        >
      </v-layout>
    </div>
  </div>
</template>

<script>
import VueNumericInput from "vue-numeric-input";
import { mapGetters, mapActions } from "vuex";
import { formatThousands } from "@/Helper";

export default {
  name: "ProductDetail",
  mixins: [formatThousands],
  computed: {
    ...mapGetters(["currentProduct", "currentCategory", "loadingCategories"]),
    maxItems() {
      return this.currentProduct.inStock ? this.currentProduct.inStock: 10;
    }
  },
  components: {
    VueNumericInput,
  },
  data() {
    return {
      qty: 1,
    };
  },
  methods: {
    ...mapActions(["addToCart"]),  
  },
};
</script>

<style lang="scss">
.product-detail {
  .product-description {
    span {
      display: block;
      color: #777;
      &:first-child {
        margin-bottom: 20px;
        color: #000000;
      }
    }
  }
  .price {
    font-size: 22px;
  }
  .vue-numeric-input {
    input {
      outline: none;
      height: 100%;
    }
    .btn {
      background: transparent;
      box-shadow: none;
      border: none;
      &:hover {
        background: #fa3600;
      }
    }
  }
}
</style>
