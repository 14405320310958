<template>
    <v-card flat>
        <v-card-title class="pb-0">
            <h3 class="checkout-section-title">{{ $t('checkout.deliveryTitle') }}</h3>
        </v-card-title>
        <v-card-text>          
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                            @focus="onEmailFocus"                     
                            v-model="form.customer.email"
                            :label="$t('checkout.email')"
                            :rules="[rules.required, rules.email]"
                            :error-messages="error"
                            :loading="loading"
                            :error="error !== null"
                            :append-icon="success ? 'mdi-check' : ''"
                            type="email"
                            name="email"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-checkbox
                            class="mt-0"
                            v-model="company"
                            :label="$t('checkout.isCompany')"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row v-if="company">
                <v-col cols="12" md="12">
                    <v-text-field
                            v-model="form.customer.companyName"
                            :label="$t('checkout.company')"
                            required
                            :rules="[rules.required]"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="company">
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="form.customer.companyCRN"
                            :label="$t('checkout.singleVat')"
                            required
                            :rules="[rules.required]"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="form.customer.companyVAT"
                            :label="$t('checkout.companyVat')"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="company">
                <v-col cols="12" md="12">
                    <v-text-field
                            v-model="form.customer.address.street"
                            :label="$t('checkout.street')"
                            required
                            :rules="[rules.required]"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="company">
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="form.customer.address.town"
                            :label="$t('checkout.city')"
                            required
                            :rules="[rules.required]"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="form.customer.address.zip"
                            :label="$t('checkout.zip')"
                            required
                            :rules="[rules.required]"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-overlay :value="checkingCustomer">
            <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    export default {
        name: 'DeliveryAddress',
        props: ['form', 'refs'],
        data() {
            return {
                validEmail: null,
                company: false,              
                loading: false,
                rules: window.validationRules.getRules(),
                error: null,
                success: false
            }
        },
        computed: {
            ...mapGetters([
                'checkingCustomer',
                'user'
            ]),
        },
        methods: {
            ...mapActions([
                'checkCustomerCard'
            ]),
            getCustomerByCard() {

                if (this.form.customer.couponCode && this.form.customer.email) {
                    this.checkCustomerCard({coupon: this.form.customer.couponCode, email: this.form.customer.email});
                }
            },
            onEmailFocus() {
                this.error = null;     
            },
            emailValidation() {                                                         
              if (this.refs.checkoutForm.validate()) {
                this.loading = true;
                this.error = null;                
                window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_ROOT + '/email/validate/' + this.form.customer.email, this.form)
                .then(response => { this.onSuccess(response) })
                .catch(error => {this.onError(error)});
              }  else {
                this.loading = false;
                this.$emit('emailValidated', false);  
              }                          
            },
            onSuccess(response) {              
              if (response.data.isValid) {
                this.error = null;
                this.success = true;
              } else {
                this.success = false;                
                this.error = this.$t('cart.invalidEmail');        
              }
              this.loading = false;
              this.validEmail = this.form.customer.email;
              this.$emit('emailValidated', this.success);
            },
            onError(error) { // eslint-disable-line
              this.loading = false;
              this.error = this.$t('cart.invalidEmail');        
              this.success = false;                  
              this.$emit('emailValidated', this.success);
            }
        }
    }
</script>
