<template>
  <v-card-text class="black--text">
    <h1 class="mb-3">Weitere Informationen zu Cookies</h1>
    <h3>Was ist ein Cookie?</h3>
    <p>Als Cookies bzw. Cookie wird in HTTP als Textdatei bezeichnet - eine kleine Datenmenge, die Server an den Browser senden. Er speichert sie auf dem Computer des Benutzers. Bei jedem Besuch der Seite sendet der Browser diese Daten an den Server zurück.</p>
    <p>Cookies werden in der Regel verwendet, um einzelne Benutzer zu unterscheiden, sie speichern typischerweise den Inhalt des "Warenkorbs" im E-Shop, Benutzereinstellungen und mehr. Technisch gesehen handelt es sich um eine Reihe von Codes, die der Browser sammelt und dann Informationen über Ihr Verhalten an den entsprechenden Server zurücksendet. Cookies enthalten Informationen darüber, wie lange der Browser sie speichern soll (von einmalig bis zu mehreren Jahren).</p>
    <p>Cookies sind nicht direkt mit Ihrem Namen oder Ihrer E-Mail-Adresse verknüpft, enthalten jedoch häufig Ihre Zugangsdaten zum System. Sie sind für eine Browserinstallation üblich. Cookies werden in keiner Weise gesichert, einschließlich der gesammelten Daten.</p>
    <h3>Welche Cookies verwenden wir?</h3>
    <p>Wir verwenden Cookies von Drittanbietern, insbesondere Google Analytics zur Verkehrsanalyse. Diese Cookies werden von Dritten kontrolliert und wir haben keinen Zugriff, um diese Daten zu lesen oder zu schreiben. Sie können hier über die Bedingungen für die Verwendung von Google Analytics lesen <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">hier</a>.</p>
    <h3>Was kannst du tun?</h3>
    <p>Sie können Cookies ablehnen oder bei Bedarf löschen. Anweisungen dazu finden Sie im Abschnitt "Hilfe" Ihres Webbrowsers.</p>
  </v-card-text>
</template>

<script>
export default {
  name: "Cookies-de"
}
</script>