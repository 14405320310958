<template>
    <div>
        <v-overlay :value="checkingCustomer">
            <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'CheckCustomer',
        mounted() {
            let customerId = this.$router.currentRoute.query.customerId;
            if (typeof customerId === 'undefined' || !customerId.length) {
                this.$router.push('/');
                return;
            }

            let productId = this.$router.currentRoute.query.productId;

            this.checkCustomer({customer: customerId, product: productId});
        },
        computed: {
            ...mapGetters([
                'checkingCustomer'
            ])
        },
        methods: {
            ...mapActions([
                'checkCustomer'
            ])
        }
    }
</script>
