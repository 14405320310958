<template>
  <v-card-text class="black--text">
    <h1 class="mb-3">Více informací o cookies</h1>
    <h2 class="mb-1">Co je to cookie?</h2>
    <p>Jako cookies, resp. cookie, se v protokolu HTTP označuje textový soubor – malé množství dat, která servery pošlou prohlížeči. Ten je uloží na počítači uživatele.
      Při každé další návštěvě stránek pak prohlížeč tato data posílá zpět serveru.
      Cookies běžně slouží k rozlišování jednotlivých uživatelů, ukládá se do nich typicky obsah "nákupního košíku" v e-shopu, uživatelské předvolby a další.
      Technicky jde o sérii kódů, podle kterých prohlížeč shromažďuje a následně odesílá informace o vašem chování zpět na příslušný server.
      Cookies obsahují informaci o tom, jak dlouho je má prohlížeč uchovávat (od jednorázových až po několikaleté).
      Cookies nejsou přímo vázány na vaše jméno ani e-mailovou adresu, často ale obsahují vaše přihlašovací údaje do systému. Jsou společné pro jednu instalaci prohlížeče. Cookies nejsou nijak zabezpečeny, a to včetně shromážděných údajů.</p>
    <h2 class="mb-1">Jaké cookies používáme?</h2>
    <p>Používáme cookies třetích stran, konkrétně Google Analytics pro analýzu návštěvnosti.
      Tyto cookies jsou řízeny třetími stranami a nemáme přístup ke čtení nebo zápisu těchto dat.
      O podmínkách užití Google Analytics se můžete dočíst <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank">zde</a></p>
    <h2 class="mb-1">Co můžete?</h2>
    <p>Soubory cookies můžete odmítnout nebo v případě potřeby vymazat. Pokyny, jak to udělat, najdete v sekci "Nápověda" vašeho webového prohlížeče.</p>
  </v-card-text>
</template>

<script>
export default {
  name: "Cookies-cs"
}
</script>