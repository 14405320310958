<template>
  <v-snackbar v-model="snackbar.active" :color="snackbar.type" :timeout="-1">
    <div style="text-align: center;color:black;">{{ snackbar.message }}</div>
    <div style="margin-top: 10px;text-align: center;">
    <v-btn v-if="snackbar.button" dark variant="flat" color="primary" @click="goToCheckout">
      {{ $t("cart.toCheckout") }}
    </v-btn>
  </div>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Snackbar",
  computed: {
    ...mapGetters(["snackbar"]),
  },
  methods: {
    ...mapActions(["hideSnackbar"]),
    goToCheckout() {
      this.hideSnackbar();
      this.$router.push("/checkout");
    },
  },
};
</script>
