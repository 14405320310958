<template>
  <v-card-text class="black--text">
    <h1 class="mb-3">COOKIES - MORE INFORMATION</h1>
    <h3>What is cookie?</h3>
    <p>In HTTP protocol cookies, or cookie, is a text file – a small amount of data that servers send to a browser. The browser will save the data on the user’s computer. Each time the user visits webpages, the browser sends these data back to the server.</p>
    <p>Cookies are commonly used to distinguish individual users. Typically, they store contents of a "shopping cart" in e-shop, user preferences and so on. Technically, these are series of codes that the browser collects and then sends back the information about user’s behaviour to the appropriate server. Cookies contain information about how long the browser should keep them (from one-time to several years).</p>
    <p>Cookies are not directly linked to your name or e-mail address, but often contain your system login information. They are common for one browser instance. Cookies are not secured by any means, including collected data.</p>
    <h3>What kind of cookies do we use?</h3>
    <p>We use third-party cookies, namely Google Analytics for traffic analysis. These cookies are managed by third parties and we do not have access to read or write these data. You can learn more about Google Analytics Terms of Service <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">here</a>.</p>
    <h3>What can you do?</h3>
    <p>You can refuse or, if necessary, delete cookies. You will find instructions on how to do it in your web browser, section „Help“.</p>
  </v-card-text>
</template>

<script>
export default {
  name: "Cookies-en"
}
</script>