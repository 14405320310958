<template>
  <v-container>
    <v-row class="mb-7" v-if="orderId">
      <v-col cols="12" class="text-center" v-if="!orderId.resultText">
        <h2 class="mt-7 mb-3">{{ $t("checkoutSuccess.successfulOrder") }}</h2>
        <p>
          {{ $t("checkoutSuccess.orderNumber") }}: {{ orderId.orderNumber }}
        </p>
        <div v-if="orderId.bankAccount" class="mb-3">
          <div>
            {{ $t("checkoutSuccess.bankAccount") }}:
            <strong>{{ orderId.bankAccount }}</strong>
          </div>
          <div>
            {{ $t("checkoutSuccess.amount") }}:
            <strong>
              {{
                $t("homepage.currency", {
                  price: orderId.totalWithVat,
                })
              }}
            </strong>
          </div>
          <div>
            {{ $t("checkoutSuccess.variableSymbol") }}:
            <strong>{{ orderId.bankVariableSymbol }}</strong>
          </div>
        </div>
        <div v-if="orderId.spaidQR" class="mb-3">
          <img :src="qrCode" alt="QrCode" />
        </div>
        <div
          v-if="$vuetify.breakpoint.xsOnly && orderId.spaidFile"
          class="my-3 mb-5"
        >
          <div class="my-3">
            {{ $t("checkoutSuccess.payByApplicationText") }}
          </div>
          <v-btn
            color="primary"
            depressed
            small
            tile
            :href="orderId.spaidFile"
            >{{ $t("checkoutSuccess.payByApplicationButton") }}</v-btn
          >
        </div>
        <p>{{ $t("checkoutSuccess.notification") }}</p>

        <v-row class="mb-4" v-if="!loading">
        <v-col cols="12" class="text-center">
            <h2 class="mb-2">{{ $t("checkoutSuccess.downloadTickets") }}</h2>            
            <v-btn
              v-if="canDownloadPdf"
              large
              @click="generateReport()"
              tile
              color="primary"
              depressed
              >{{ $t("checkoutSuccess.downloadPdf") }}</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" offset-sm="3">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item, i) in productReadyQrCodes"
                :key="i"
              >
                <v-expansion-panel-header>
                  {{ item.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-btn
                    v-if="item.registrationUrl"
                    large
                    :href="item.registrationUrl" 
                    target="_blank"
                    tile
                    color="primary"
                    depressed
                  >{{ $t("checkoutSuccess.ticketRegistration") }}</v-btn>

                  <img v-if="!item.registrationUrl" :src="item.qrCode" :alt="item.name" />
                  <p v-if="!item.registrationUrl">
                    {{ $t("checkoutSuccess.ticketNumber") }}:
                    <strong>{{ item.ticketNumber }}</strong>
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"          
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          ref="html2Pdf"
          :paginate-elements-by-height="1500"
          :html-to-pdf-options="{
            margin: 6,
            filename: 'tickets.pdf',
            image: {
              type: 'jpeg',
              quality: 1,
            },
            pagebreak: {
              mode: ['css', 'legacy'],
              avoid: '.page-breaker',
            },
            html2canvas: {
              scale: 1,
              useCORS: true,
              allowTaint: true,
              scrollX: 0,
              scrollY: 0,
            },
            jsPDF: {
              format: 'a4',
              orientation: 'portrait',
            },
          }"
        >
          <section slot="pdf-content">
            <div>
              <section style="height: 100px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 60px;
                  "
                >                
                  <img :src="require('../assets/logo_sirka.png')" />
                  <h1 style="font-size: 2em">
                    {{ $t("checkoutSuccess.downloadTickets") }}
                  </h1>
                </div>
              </section>
              <div
                v-for="(qrCode, i) in productReadyQrCodes"
                :key="i"
                style="
                  padding-left: 20px;
                  padding-right: 40px;
                  margin-bottom: 30px;
                "
              >
                <section v-if="qrCode.downloadPdf"
                  class="page-breaker"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 30px;
                  "
                >
                  <div>
                    <h2 style="margin-bottom: 10px">{{ qrCode.name }}</h2>
                    <p v-if="qrCode.validFrom">
                      {{ $t("checkoutSuccess.validFrom") }}
                      {{ formatDate(qrCode.validFrom) }}
                    </p>
                    <p v-if="qrCode.validTo">
                      {{ $t("checkoutSuccess.validTo") }}
                      {{ formatDate(qrCode.validTo) }}
                    </p>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <img :src="qrCode.qrCode" :alt="qrCode.name" />
                    <strong>{{ qrCode.ticketNumber }}</strong>
                  </div>
                </section>
                <v-divider v-if="qrCode.downloadPdf" />
              </div>
            </div>
          </section>
        </vue-html2pdf>

        <v-btn color="primary" depressed tile @click="$router.push('/')">{{
          $t("checkoutSuccess.backToStore")
        }}</v-btn>
      </v-col>
      <v-col cols="12" class="text-center" v-else>
        <h2 class="mt-7 mb-3">{{ $t("checkoutSuccess.orderError") }}</h2>
        <p>
          {{ $t("checkoutSuccess.orderNumber") }}: {{ orderId.orderNumber }}
        </p>
        <p>
          {{ $t("checkoutSuccess.error") }}:
          <span v-text="orderId.resultText"></span>
        </p>
        <v-btn color="primary" depressed tile @click="$router.push('/')">{{
          $t("checkoutSuccess.backToStore")
        }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import { format, parseISO } from "date-fns";

export default {
  name: "CheckoutSuccess",
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      qrCode: null,
      productReadyQrCodes: [],
      loading: true,
    };
  },
  mounted() {
    if (!this.orderId) {
      this.$router.push("/");
    } else {
      this.productQrCodes();
      if (this.orderId.spaidQR) {
        this.fetchQRCode(this.orderId.spaidQR);
      }
      if (this.$gtm.enabled() && this.orderId.products) {
        this.$gtm.trackEvent({
          event: "purchase", // Event type [default = 'interaction'] (Optional)
          category: "Ecommerce",
          action: "Purchase",
          ecommerce: {
            currencyCode: "CZK",
            purchase: {
              actionField: {
                id: this.orderId.orderNumber,
                revenue: this.orderId.totalWithVat,
                shipping: 0,
                //coupon:
              },
              products: this.orderId.products,
            },
          },
        });
      }
    }
  },
  computed: {
    ...mapGetters(["orderId"]),
    canDownloadPdf: function () { 
      return !!this.productReadyQrCodes.find(x => x.downloadPdf);       
    }
  },
  methods: {
    formatDate(date) {
      return format(parseISO(date), "dd. MM. yyyy");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async fetchQRCode(code) {
      let response = await fetch(code);

      if (response.ok) {
        let json = await response.text();
        this.qrCode = json.slice(1, -1);
      } else {
        console.log(response.status);
      }
    },
    productQrCodes() {
      if (!this.orderId.products) {
        this.productReadyQrCodes = [];
        return;
      }

      const alreadyInArray = [];
      const urls = [];
      this.orderId.products.map((product) => {
        if (product.qrCodeUrl) {
          let propName = product.name;
          if (alreadyInArray.includes(propName)) {
            propName =
              alreadyInArray.filter((item) => item === propName).length + 1;
            propName = product.name + " " + propName;
            alreadyInArray.push(product.name);
          } else {
            alreadyInArray.push(product.name);
            propName = product.name + " 1";
          }

          urls.push(
            window.axios.get(product.qrCodeUrl, {
              name: propName,
              ticketNumber: product.ticketNumber,
              validFrom: product.validFrom,
              validTo: product.validTo,
              registrationUrl: product.registrationUrl,
              downloadPdf: product.downloadPdf
            })
          );
        }
      });

      Promise.all(urls).then((responses) => {
        responses.map((response) => {          
          this.productReadyQrCodes.push({
            name: response.config.name,
            qrCode: response.data,
            ticketNumber: response.config.ticketNumber,
            validFrom: response.config.validFrom,
            validTo: response.config.validTo,
            registrationUrl: response.config.registrationUrl,
            downloadPdf: response.config.downloadPdf
          });
        });
        this.loading = false;
      });
    },
  },
};
</script>
