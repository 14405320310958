export const getProductImage = {
    methods: {
        getProductImage(image) {
            if (!image) {
                return require('../assets/products/placeholder-min.png');
            }
            return image;
        }
    }
};

export const formatThousands = {
    methods: {
        formatThousands(item) {
            if (item) {
                return item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return 0;
        },
    }
};